@tailwind base;

@tailwind components;

@tailwind utilities;

@import "/node_modules/@fortawesome/fontawesome-free/css/all.css";

* {
  box-sizing: border-box;
}

.navbar {
  background-color: #457b9d;
  display: flex;
  flex-flow: row wrap;
  color: white;
  align-items: center;
  justify-content: space-between;
  padding: 0 75px;
}
.navbar ul {
  padding: 0.5em;
}

.navbar li {
  padding: 0.2em;
  display: inline-block;
}

.white {
  background-color: #efecca;
}

.blue {
  background-color: #f1faee;
}

.secondary {
  background-color: #457b9d;
}

.purple {
  background-color: #1d3557;
}

.orange {
  background-color: #e18335;
}

.profile-links a:hover {
  color: #fb8500;
}

.intro {
  grid-area: section-1;
  height: 600px;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.2)),
    url("../img/chicago.jpg") no-repeat center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.skills {
  grid-area: section-2;
}

.notable-projects {
  grid-area: section-3;
}

.triangle {
  grid-area: interval;
}

.triangle-inv {
  grid-area: interval2;
}

.header p {
  max-width: 1500px;
  margin: 0 auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.short-description {
  max-width: 1500px;
  margin: 0 auto;
}

.main {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: auto;
  grid-template-areas:
    "section-1"
    "section-2"
    "interval"
    "section-3"
    "interval-inv";
}

.skill-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 48rem;
}

.skill-inner {
  background-color: #edf2f7;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  border-radius: 0.5rem;
  padding: 1rem;
  margin-top: 1rem;
  width: 100%;
}

.skill-inner h1 {
  font-size: 1.5rem;
  padding-left: 1rem;
  font-family: Georgia, Cambria, "Times New Roman", Times, serif;
}

.skill-item {
  display: flex;
  padding: 0.5rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  flex-direction: column;
  align-items: center;
}

.skill-item-description {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-self: flex-start;
  padding: 1.25rem;
}

.skill-item-description h2 {
  font-size: 1.25rem;
  font-weight: 700;
}

.skill-item-description p {
  font-size: 1rem;
  color: #2f3541;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.skill-item-description p.tech {
  font-weight: 700;
}

.project-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  max-width: 1500px;
}

.project-item {
  width: 100%;
}

.project-item:hover {
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  transform: scale(1.05, 1.05);
  transition-delay: 1s;
  transition: all 0.3s ease-in-out;
}
/* Large Devices */
@media (min-width: 1200px) {
  .skill-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    max-width: 1500px;
  }
  .project-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
  }

  .project-item {
    width: 33.333333%;
  }

  .skill-item {
    /*flex-direction: row;*/
  }

  .skill-inner {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .navbar {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
  }

  .navbar li {
    padding: 1em;
    display: inline-block;
  }
}
